<template>
  <div>
    <div class="w">
      <div class="latest-news-container">
        <div class="latest-news-title">{{title}}</div>
        <div class="latest-news-publish-time">{{content}}</div>
        <div class="latest-news-img-box">
          <div class="latest-news-img" :style="{backgroundImage:'url(' + picPath + ')'}">
          </div>
        </div>
        <div class="latest-news-content">
          <div v-html="detail"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      content: "",
      detail: "",
      picPath: "",
    };
  },
  created() {
    this.getParams();
  },
  methods: {
    getParams() {
      if (!this.$route.query) {
        return;
      }
      if (this.$route.query.introductionJson) {
        this.detail = JSON.parse(this.$route.query.introductionJson).content;
      }
      this.title = this.$route.query.title;
      this.content = this.$route.query.content;
      this.picPath = this.$route.query.picPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.w {
  margin-top: 230px;
  background: #ffffff;
  border-radius: 28px;
}
.latest-news-container {
  margin: 0 auto;
  width: 820px;
  .latest-news-title {
    font-size: 36px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
  }
  .latest-news-publish-time {
    margin: 58px 0;
    font-size: 18px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
  }
  .latest-news-img-box {
    display: flex;
    justify-content: center;
    width: 100%;
    .latest-news-img {
      width: 580px;
      height: 370px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
    }
  }
  .latest-news-content {
    margin-top: 68px;
    margin-bottom: 70px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #717171;
    line-height: 35px;
    p {
      text-indent: 2em;
    }
  }
}
</style>